import { Component } from '@angular/core';

@Component({
	selector: 'pipelines-left-nav',
	templateUrl: './left-nav.component.html',
	styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent {

	constructor() { }
}
