import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetRowsRequest } from '@pipelines/app/models/requests/get-rows-request';
import { GridData } from '@pipelines/app/models/grid-data';
import { PipelinesClient } from '../clients/pipelines.client';
import { map } from 'rxjs/operators';
import { PipelinesStateResponseResourceToGridDataAdapter } from '../adapters/pipelines-state-response-resource-to-grid-data.adapter';
const pipelinesStateResponseResourceToGridDataAdapter = new PipelinesStateResponseResourceToGridDataAdapter();

@Injectable({
	providedIn: 'root'
})
export class GridAccess {

	constructor(private pipelinesClient: PipelinesClient) { }

	public setGetRowsRequest(getRowsRequest: GetRowsRequest): Observable<GridData> {
		return this.pipelinesClient.getGridData$(getRowsRequest).pipe(
			map((response) => pipelinesStateResponseResourceToGridDataAdapter.adapt(response))
		);
	}
}
