import { Component, Input, OnInit } from '@angular/core';
import { StatusEntry } from '@pipelines/app/models/status-entry';
import { RowDef } from '@pipelines/app/models/row-def';
import {
	StatusDisplay
} from '@pipelines/app/components/grid/renderers/actions-renderer/actions-info-menu/status-display';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';
import { DateFormatter } from '@common/lib/utilities/date-formatter.utility';
import { ArchiveUtil } from '@pipelines/app/components/grid/archive-util';
import { WithdrawData } from '@pipelines/app/models/withdraw-data';

@Component({
	selector: 'pipelines-status-display-container',
	templateUrl: './status-display-container.component.html',
	styleUrls: ['./status-display-container.component.scss']
})
export class StatusDisplayContainerComponent implements OnInit {

	@Input() statusHistory: StatusEntry[];
	@Input() rowData: RowDef;
	@Input() withdrawData: WithdrawData;
	@Input() isPreSubmit: boolean;
	public statusDisplays: StatusDisplay[];

	constructor() {
	}

	ngOnInit(): void {
		this.statusDisplays = this.getStatusDisplays();
	}

	private getStatusDisplays(): StatusDisplay[] {
		const mostRecentStatusName = this.statusHistory ? this.statusHistory[0].name : this.rowData.status;
		switch (mostRecentStatusName) {
			case knownApplicationStatus.Archived:
				return this.getArchivedState();
			case knownApplicationStatus.Withdrawn:
				return this.getWithdrawnState();
			case knownApplicationStatus.Approved:
			case knownApplicationStatus.Denied:
			case knownApplicationStatus.Onboarded:
				return this.getTerminalState();
			case knownApplicationStatus.InReview:
				return this.getInReviewState();
			case knownApplicationStatus.New:
			case knownApplicationStatus.InProgress:
				return this.getNewState();
			default:
				return null;
		}
	}

	private getArchivedState(): StatusDisplay[] {
		const statusDisplays: StatusDisplay[] = [];
		statusDisplays.push(this.getCreatedStatusDisplay());
		if (this.rowData.submitDate) {
			statusDisplays.push(this.getSubmittedStatusDisplay());
		}
		statusDisplays.push(this.getArchiveStatusDisplay());
		return statusDisplays;
	}

	private getWithdrawnState(): StatusDisplay[] {
		const statusDisplays: StatusDisplay[] = [];
		statusDisplays.push(this.getCreatedStatusDisplay());
		if (this.rowData.submitDate) {
			statusDisplays.push(this.getSubmittedStatusDisplay());
		}
		if (this.withdrawData) {
			statusDisplays.push(this.getWithdrawnStatusDisplay());
		}
		return statusDisplays;
	}

	private getTerminalState(): StatusDisplay[] {
		const statusDisplays: StatusDisplay[] = [];
		statusDisplays.push(this.getCreatedStatusDisplay());
		if (this.rowData.submitDate) {
			statusDisplays.push(this.getSubmittedStatusDisplay());
		}
		if (this.statusHistory) {
			statusDisplays.push(this.getDecisionedDisplay());
		}
		return statusDisplays;
	}

	private getInReviewState(): StatusDisplay[] {
		const statusDisplays: StatusDisplay[] = [];
		statusDisplays.push(this.getCreatedStatusDisplay());
		if (this.rowData.submitDate) {
			statusDisplays.push(this.getSubmittedStatusDisplay());
		}
		statusDisplays.push(this.getArchiveStatusDisplay());
		return statusDisplays;
	}

	private getNewState(): StatusDisplay[] {
		const statusDisplays: StatusDisplay[] = [];
		statusDisplays.push(this.getCreatedStatusDisplay());
		statusDisplays.push(this.getArchiveStatusDisplay());
		return statusDisplays;
	}

	private getStatusDisplay(date: Date, text: string, subtext?: string, subtextColorClass?: string): StatusDisplay {
		const display = new StatusDisplay();
		display.date = DateFormatter.formatDate(date, 'default', { month: 'long', day: 'numeric', year: 'numeric' });
		display.text = text;
		display.subtext = subtext;
		display.subtextColorClass = subtextColorClass;
		return display;
	}

	private getCreatedStatusDisplay(): StatusDisplay {
		return this.getStatusDisplay(new Date(this.rowData.createdDate), 'Created');
	}

	private getSubmittedStatusDisplay(): StatusDisplay {
		return this.getStatusDisplay(new Date(this.rowData.submitDate), 'Submitted');
	}

	private getArchiveStatusDisplay(): StatusDisplay {
		let isArchived;
		let statusEntry: StatusEntry;
		if (this.statusHistory) {
			statusEntry = this.statusHistory[0];
			isArchived = statusEntry.name === knownApplicationStatus.Archived;
		} else {
			isArchived = this.rowData.status === knownApplicationStatus.Archived;
		}

		if (isArchived && statusEntry) { // check for statusEntry for backwards compatibility
			return this.getStatusDisplay(new Date(statusEntry.updateDate), 'Archival', 'automatically archived');
		} else if (isArchived) {
			// const archivalDate = ArchiveUtil.getArchivalDate(new Date(this.rowData.createdDate), this.rowData.applicationType, false);
			return this.getStatusDisplay(new Date(this.rowData.rowSupplement.archivalDate), 'Archival', 'automatically archived');
		} else {
			// const futureArchivalDate = ArchiveUtil.getArchivalDate(new Date(this.rowData.createdDate), this.rowData.applicationType, this.isPreSubmit);
			const futureArchivalDate = new Date(this.rowData.rowSupplement.archivalDate);
			const daysUntilArchival = ArchiveUtil.getDaysUntilArchival(futureArchivalDate);
			return this.getStatusDisplay(futureArchivalDate, 'Archival', `${daysUntilArchival} days until archive`, 'text-red-600');
		}
	}

	private getWithdrawnStatusDisplay(): StatusDisplay {
		const mostRecentWithdrawnStatus = this.statusHistory.find((status) => status.name === knownApplicationStatus.Withdrawn);
		if (this.withdrawData) {
			return this.getStatusDisplay(new Date(mostRecentWithdrawnStatus.updateDate), 'Withdrawn', this.withdrawData.author);
		}
	}

	private getDecisionedDisplay(): StatusDisplay {
		const mostRecentDecisionedStatus = this.statusHistory.find((status) =>
			status.name === knownApplicationStatus.Approved || status.name === knownApplicationStatus.Denied);
		return this.getStatusDisplay(new Date(mostRecentDecisionedStatus.updateDate), 'Decisioned');
	}
}
