import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GridReadyEvent } from '@pipelines/app/models/grid-ready.interface';
import { ApplicationService } from '@pipelines/app/services/application.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
	DocumentsUploadedRendererComponent
} from '@pipelines/app/components/grid/renderers/documents-uploaded-renderer/documents-uploaded-renderer.component';
import {
	ActionsRendererComponent
} from '@pipelines/app/components/grid/renderers/actions-renderer/actions-renderer.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { GridDatasource } from '@pipelines/app/components/grid/datasource/grid-datasource';
import {
	ApplicantRendererComponent
} from '@pipelines/app/components/grid/renderers/applicant-renderer/applicant-renderer.component';
import { DateRendererComponent } from '@pipelines/app/components/grid/renderers/date-renderer/date-renderer.component';
import { ColumnMenuTabs } from '@pipelines/app/components/grid/column-menu-tabs.enum';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import {
	ApplicationTypeRendererComponent
} from '@pipelines/app/components/grid/renderers/application-type-renderer/application-type-renderer.component';
import {
	StatusRendererComponent
} from '@pipelines/app/components/grid/renderers/status-renderer/status-renderer.component';
import { ColumnName } from '@pipelines/app/components/grid/column-names.consts';
import {
	filterApplicationTypeMap,
	filterDocumentsUploadedMap,
	filterStatusMap
} from '@pipelines/app/components/grid/filter-map';
import { KnownEvents } from '@pipelines/app/models/requests/intents/known-events';
import {
	toAgGridFilterInstance,
	toBlueFinSetFilter,
	toBlueFinTextFilter
} from '@pipelines/app/components/grid/filter.util';

@Component({
	selector: 'pipelines-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnDestroy {

	@ViewChild('searchInput') public searchInput: ElementRef<HTMLInputElement>;
	@ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
	public gridOptions: GridOptions;
	public totalRows: number;
	public pageSizeOptions: number[] = [25, 50, 100, 150, 200];
	public pageSize: number = this.pageSizeOptions[0];
	public searchText: string;
	public columnDefs$: Observable<ColDef[]>;
	public isResetViewDisabled: boolean;
	private hasOverlay: boolean = false;
	private destroy$: Subject<void> = new Subject<void>();
	private queryParams: Params;
	private isInitialLoad = true;

	private gridApi: GridApi;
	private columnApi: ColumnApi;

	constructor(
		private readonly applicationService: ApplicationService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly gridDatasource: GridDatasource)  {
		this.setGridOptions();
	}

	public ngOnInit(): void {
		// eslint-disable-next-line no-underscore-dangle
		this.paginator._intl.itemsPerPageLabel = 'Rows per page:';
		this.columnDefs$ = this.gridDatasource.columnDefs$.pipe(
			first((columnDefs) => columnDefs.length > 0),
			map((columnDefinitions) => columnDefinitions?.filter((columnDefinition) => columnDefinition.cellRenderer !== 'hiddenRenderer'))
		);
		this.route.queryParams.subscribe((queryParams) => {
			this.queryParams = queryParams;
			this.searchText = queryParams['searchText'] ?? '';
		});
		this.setupRefreshSubscription();
	}

	public ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onGridReady({ api, columnApi }: GridReadyEvent): void {
		this.gridApi = api;
		this.columnApi = columnApi;
	}

	public onGridUpdated(): void {
		if (_.isNil(this.gridApi)) {
			this.isResetViewDisabled = true;
		} else {
			this.isResetViewDisabled = _.isEmpty(this.gridApi.getFilterModel()) && !this.searchText && !this.queryParams['sortModel'];
		}
		this.totalRows = this.gridApi?.getDisplayedRowCount() ?? 0;
		if (this.totalRows > 0 && this.isInitialLoad) {
			this.setInitialFilterInstances();
			this.isInitialLoad = false;
		}
		this.columnApi?.autoSizeAllColumns();
	}

	public onSearch(): void {
		const searchInputValue = this.searchInput.nativeElement.value.trim();
		this.addQueryParams({ searchText: searchInputValue.length > 0 ? searchInputValue : null });
		this.gridApi.refreshServerSideStore({ purge: true });
	}

	public onFilterChanged(): void {
		const filterModel = this.modifyOutgoingFilter();
		this.addQueryParams({ filterModel: JSON.stringify(filterModel) });
	}

	public onSortChanged(): void {
		let sortModel = null;
		const sortedColumn = _.find(this.columnApi.getColumnState(), (column) => column.sort !== null);
		if (sortedColumn) {
			const sortObject = _.pick(sortedColumn, ['colId', 'sort']);
			sortModel = JSON.stringify([sortObject]);
		}
		this.addQueryParams({ sortModel });
	}

	public onPagination({ pageSize, pageIndex, previousPageIndex }: PageEvent): void {
		if (pageSize === this.pageSize) {
			this.handlePagination(previousPageIndex, pageIndex);
		} else {
			this.handleUpdatePageSize(pageSize);
		}
	}

	public setupRefreshSubscription(): void {
		this.applicationService.getProcessingApplicationEvent$()
			.pipe(
				filter((evt) => evt?.eventName === KnownEvents.withdraw),
				takeUntil(this.destroy$)
			)
			.subscribe((processingApplicationEvent) => {
				if (processingApplicationEvent.isProcessing) {
					this.hasOverlay = true;
					this.gridApi.showLoadingOverlay();
				} else if (this.hasOverlay && !processingApplicationEvent.isProcessing) {
					this.gridApi.hideOverlay();
					this.gridApi.refreshServerSideStore({ purge: true });
					this.hasOverlay = false;
				}
			});
	}

	public onResetView(): void {
		this.columnApi.resetColumnState();
		this.gridApi.setFilterModel(null);
		this.router.navigate(['/'], {
			relativeTo: this.route,
			queryParams: { searchText: null, sortModel: null },
			queryParamsHandling: 'merge'
		});
		this.gridApi.refreshServerSideStore({ purge: true });
	}

	private handleUpdatePageSize(pageSize): void {
		this.pageSize = pageSize;
		this.gridApi.paginationSetPageSize(this.pageSize);
	}

	private handlePagination(previousPageIndex: number, currentPageIndex: number): void {
		if (previousPageIndex < currentPageIndex) {
			this.gridApi.paginationGoToNextPage();
		} else {
			this.gridApi.paginationGoToPreviousPage();
		}
	}

	private addQueryParams(queryParams: any): void {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams,
			queryParamsHandling: 'merge'
		});
	}

	private modifyOutgoingFilter(): { [key: string]: any } {
		const filterModel = this.gridApi.getFilterModel();

		toBlueFinSetFilter(filterModel, ColumnName.documentsUploaded, filterDocumentsUploadedMap);
		toBlueFinTextFilter(filterModel, ColumnName.applicationType, filterApplicationTypeMap);
		toBlueFinTextFilter(filterModel, ColumnName.status, filterStatusMap);

		return filterModel;
	}

	private setInitialFilterInstances(): void {
		const filterModel = JSON.parse(this.queryParams['filterModel'] ?? null);
		if (_.isEmpty(filterModel)) {
			return;
		}

		for (const [key, value] of Object.entries(filterModel)) {
			const filterInstance = this.gridApi.getFilterInstance(key);
			if (filterInstance) {
				if ([ColumnName.applicationType, ColumnName.status, ColumnName.documentsUploaded].includes(key)) {
					filterInstance.setModel(toAgGridFilterInstance(key, value));
				} else {
					filterInstance.setModel(value);
				}
			}
		}
	}

	private setGridOptions(): void {
		this.gridOptions = {
			rowModelType: 'serverSide',
			serverSideDatasource: this.gridDatasource,
			serverSideStoreType: 'partial',
			cacheBlockSize: this.pageSize,
			frameworkComponents: {
				actionsRenderer: ActionsRendererComponent,
				applicantRenderer: ApplicantRendererComponent,
				dateRenderer: DateRendererComponent,
				documentsUploadedRenderer: DocumentsUploadedRendererComponent,
				statusRenderer: StatusRendererComponent,
				applicationTypeRenderer: ApplicationTypeRendererComponent
			},
			defaultColDef: {
				flex: 1,
				minWidth: 100,
				sortable: false,
				lockPosition: true,
				resizable: true,
				menuTabs: [ColumnMenuTabs.filter],
			},
			pagination: true,
			onSortChanged : this.onSortChanged.bind(this),
			onFilterChanged : this.onFilterChanged.bind(this),
			onModelUpdated: this.onGridUpdated.bind(this),
			onGridReady: this.onGridReady.bind(this),
			paginationPageSize: this.pageSize,
			suppressPaginationPanel: true
		};
	}
}
