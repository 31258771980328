import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { ApiGridFilter } from '@pipelines/app/models/resource/api-grid.filter';
import { GridFilter } from '@pipelines/app/models/grid-filter';

export class ApiGridFilterToGridFilterAdapter extends AdapterBase<ApiGridFilter, GridFilter> {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public adapt(source: ApiGridFilter): GridFilter {
		const destination = {} as GridFilter;

		if (source) {
			destination.forColumn = source.forColumn;
			destination.displayName = source.displayName;
		}

		return destination;
	}
}
