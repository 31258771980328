// these objects map the ag-grid value shown in the UI to the actual value in our system used for filtering

import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';
import { applicationType } from '@common/lib/constants/application-types';
import { ColumnName } from '@pipelines/app/components/grid/column-names.consts';

export const filterApplicationTypeMap = {
	Consumer: applicationType.Individual
};

export const filterStatusMap = {
	'In Progress': knownApplicationStatus.InProgress,
	'Manual Review': knownApplicationStatus.InReview,
	'On Hold': knownApplicationStatus.OnHold
};

export const filterDocumentsUploadedMap = {
	'No Documents': { filterType: 'text', type: 'equals', filter: '0' },
	'Has Documents': { filterType: 'text', type: 'notEqual', filter: '0' }
};

export const toFilterInstanceByValue = (columnName, values) => {
	let map;
	if (columnName === ColumnName.applicationType) {
		map = filterApplicationTypeMap;
	} else if (columnName === ColumnName.status) {
		map = filterStatusMap;
	}

	if (!map) {
		return;
	}

	for (const [key, val] of Object.entries(map)) {
		const index = values.indexOf(val);
		if (index !== -1) {
			values[index] = key;
		}
	}

	return values;
};
