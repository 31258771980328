import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetRowsRequest } from '@pipelines/app/models/requests/get-rows-request';
import { GridData } from '@pipelines/app/models/grid-data';
import { PipelinesConfiguration } from '@pipelines/app/pipelines.configuration';
import { GridDataResponse } from '@pipelines/app/models/responses/grid-data.response';
import { map } from 'rxjs/operators';
import { GridDataResponseToGridDataAdapter } from '@pipelines/app/adapters/grid-data-response-to-grid-data.adapter';

const getGridDataResponseToGridDataAdapter = new GridDataResponseToGridDataAdapter();

@Injectable({
	providedIn: 'root',
})
export class PipelineApiService {
	private readonly http = inject(HttpClient);
	private readonly config = inject(PipelinesConfiguration);

	private readonly baseUrl = `${this.config.experienceApi}/pipeline`;

	getGridData$(getRowsRequest: GetRowsRequest): Observable<GridData> {
		return this.http
			.post<GridDataResponse>(`${this.baseUrl}`, getRowsRequest)
			.pipe(map((gridDataResponse) => getGridDataResponseToGridDataAdapter.adapt(gridDataResponse)));
	}
}
