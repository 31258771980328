import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DateFormatter } from '@common/lib/utilities/date-formatter.utility';
import { RowDef } from '@pipelines/app/models/row-def';
import _ from 'lodash';

@Component({
	selector: 'pipelines-status-renderer',
	templateUrl: './status-renderer.component.html',
	styleUrls: ['./status-renderer.component.scss']
})
export class StatusRendererComponent implements AgRendererComponent {

	public params: ICellRendererParams;
	public rowData: RowDef;
	public applicationStatus: string;
	public statusUpdateDate: string;
	private camelCaseSplitRegex = /([a-z])([A-Z])/g;

	public agInit(params: ICellRendererParams): void {
		this.params = params;
		this.rowData = params.data;
		this.applicationStatus = this.formatApplicationStatus();
		this.statusUpdateDate = this.formatStatusUpdateDate();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public refresh(params: ICellRendererParams): boolean {
		return false;
	}

	private formatApplicationStatus(): string {
		let status;
		if (this.rowData.rowSupplement.statusHistory) {
			status = _.orderBy(this.rowData.rowSupplement.statusHistory, 'updateDate', 'desc')[0].name;
		} else {
			status = this.params.value;
		}
		return status.replace(this.camelCaseSplitRegex, '$1 $2');
	}

	private formatStatusUpdateDate(): string {
		const matchingStatusEntries = this.rowData.rowSupplement.statusHistory?.filter((status) => status.name === this.params.value);
		if (matchingStatusEntries?.length > 0) {
			const latestStatus = _.orderBy(matchingStatusEntries, 'updateDate', 'desc')[0];
			let dateStr = null;
			if (latestStatus !== null && !['New', 'InProgress'].includes(this.params.value)) {
				dateStr = DateFormatter.formatDate(
					new Date(latestStatus.updateDate),
					'default',
					{ month: 'long', day: 'numeric', year: 'numeric' }
				);
				return dateStr;
			}
		}

		return null;
	}
}
