import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { SortModel } from '@pipelines/app/models/sort-model.interface';
import { ServerSideGetRowsRequestToGetRowsRequestAdapter } from '@pipelines/app/adapters/server-side-get-rows-request-to-get-rows-request.adapter';
import { PipelineApiService } from '@pipelines/app/services/pipeline-api.service';

const serverSideGetRowsRequestToGetRowsRequestAdapter = new ServerSideGetRowsRequestToGetRowsRequestAdapter();

@Injectable({
	providedIn: 'root',
})
export class GridDatasource implements IServerSideDatasource {
	public columnDefs$: BehaviorSubject<ColDef[]> = new BehaviorSubject<ColDef[]>([]);
	private searchText: string;
	private filterModel: Record<string, any>;
	private sortModel: SortModel[];
	private defaultSortModel = [
		{
			colId: 'createdDate',
			sort: 'desc',
		},
	];

	private readonly route = inject(ActivatedRoute);
	private readonly pipelineApiService = inject(PipelineApiService);

	constructor() {
		this.route.queryParams.subscribe((queryParams) => this.setValuesFromQueryParams(queryParams));
	}

	public getRows(params: IServerSideGetRowsParams): void {
		try {
			const sortModel = this.sortModel || this.defaultSortModel;
			const getGridDataRequest = serverSideGetRowsRequestToGetRowsRequestAdapter.adapt(params.request, {
				searchText: this.searchText,
				filterModel: this.filterModel,
				sortModel,
			});

			this.pipelineApiService.getGridData$(getGridDataRequest).subscribe((gridData) => {
				params.success({ rowData: gridData.rowDefs, rowCount: gridData.totalItems });
				sortModel.forEach((item) => {
					const column = gridData.columnDefs.find((columnDef) => columnDef.field === item.colId);
					if (column) {
						column.sort = item.sort === 'asc' ? 'asc' : 'desc';
					}
				});

				this.columnDefs$.next(gridData.columnDefs);
			});
		} catch (err) {
			params.fail();
		}
	}

	private setValuesFromQueryParams({ searchText, filterModel, sortModel }: Params): void {
		this.searchText = searchText;
		this.filterModel = filterModel ? JSON.parse(filterModel) : null;
		this.sortModel = sortModel ? JSON.parse(sortModel) : null;
	}
}
