import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { GridFilterResource } from '@pipelines/app/models/resource/grid-filter.resource';
import { GridFilter } from '@pipelines/app/models/grid-filter';

export class GridFilterResourceToGridFilterAdapter extends AdapterBase<GridFilterResource, GridFilter> {

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public adapt(source: GridFilterResource): GridFilter {
		const destination = {} as GridFilter;

		if (source) {
			destination.forColumn = source.forColumn;
			destination.displayName = source.displayName;
		}

		return destination;
	}
}
