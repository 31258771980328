import { Component, Input } from '@angular/core';

@Component({
	selector: 'pipelines-status-display',
	templateUrl: './status-display.component.html',
	styleUrls: ['./status-display.component.scss']
})
export class StatusDisplayComponent {

	@Input() public text: string;
	@Input() public subtext: string;
	@Input() public subtextColorClass: string;
	@Input() public date: string;

	constructor() {
	}

}
