import { Injector } from '@angular/core';
import { loadConfiguration } from '@pipelines/app/startup/load-configuration';
import { setAgGridLicense } from '@pipelines/app/startup/set-ag-grid-license';

export const startupFactory = (injector: Injector): () => void => () => {
		const steps: ((x: Injector) => Promise<any>)[] = [
			loadConfiguration,
			setAgGridLicense
		];

		let index = 0;

		const runNextStep = (): Promise<any> => {
			if (index < steps.length) {
				return steps[index++](injector).then(() => runNextStep());
			}
		};

		return runNextStep();
	};
