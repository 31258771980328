<div class="mt-8">
	<button mat-icon-button disableRipple [matMenuTriggerFor]="infoMenu" (menuOpened)="emitMenuOpened()" class="cursor-pointer ml-2 mr-2" aria-label="actions">
		<span class="fa-stack fa-fw">
			<i class="far fa-info-circle -ml-5 -mt-5 text-xl opacity-60 fa-stack-2x"></i>
			<span *ngIf="isPendingArchival" class="text-xs text-left fa-stack-1x">
				<i class="fa-regular fa-hourglass-clock text-yellow-300 text-xs bg-white"></i>
			</span>
		</span>
	</button>
	<button mat-icon-button disableRipple [matMenuTriggerFor]="actionsMenu" class="cursor-pointer" aria-label="actions">
		<span class="fa-stack fa-fw">
			<i class="far fa-bullseye-pointer text-xl opacity-60 -ml-5 -mt-5 fa-stack-2x"></i>
		</span>
	</button>
	<mat-menu #infoMenu>
		<pipelines-actions-info-menu
			[menuOpenedEvent]="onMenuOpenedEvent"
			[rowData]="rowData">
		</pipelines-actions-info-menu>
	</mat-menu>
	<mat-menu #actionsMenu="matMenu">
		<button mat-menu-item class="text-center action-button" (click)="onCopyAppId()">
			<span>Copy App ID</span>
		</button>
		<hr>
		<button
			mat-menu-item
			class="text-center"
			[ngClass]="{ 'cursor-not-allowed': !rowData.originationUrl, 'action-button': rowData.originationUrl }"
			[disabled]="!rowData.originationUrl"
			(click)="onGoToAlloy()">
			<span>Go to Alloy</span>
		</button>
		<hr>
		<a [href]="mailTo">
			<button mat-menu-item class="text-center action-button">
				<span>Email Primary User</span>
			</button>
		</a>
		<hr>
		<button mat-menu-item class="text-center"
				[ngClass]="{ 'cursor-not-allowed': disableWithdraw, 'action-button': !disableWithdraw }"
				[disabled]="disableWithdraw"
				(click)="onWithdrawApplication()">
			<span>Withdraw Application</span>
		</button>
	</mat-menu>
</div>
