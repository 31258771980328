<div class="px-3 flex flex-col w-64">
	<pipelines-status-display-container
		[statusHistory]="statusHistory"
		[rowData]="rowData"
		[withdrawData]="withdrawData"
		[isPreSubmit]="isPreSubmit"
	>
	</pipelines-status-display-container>
	<hr *ngIf="!isTerminalStatus || withdrawData" />
	<ng-container *ngIf="withdrawData">
		<pipelines-withdrawn-comment [withdrawData]="withdrawData"></pipelines-withdrawn-comment>
	</ng-container>
	<ng-container *ngIf="!isTerminalStatus">
		<pipelines-review-reasons
			[applicationType]="rowData.applicationType"
			[applicationId]="rowData.applicationId"
			[onMenuOpenedEvent]="menuOpenedEvent"
		>
		</pipelines-review-reasons>
	</ng-container>
</div>
