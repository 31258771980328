import { ColDef } from 'ag-grid-community';
import { GridFilter } from '@pipelines/app/models/grid-filter';
import { RowDef } from '@pipelines/app/models/row-def';

export class GridData {
	availableFilters: GridFilter[];
	selectedFilters: GridFilter[];
	totalItems: number;
	totalPages: number;
	rowDefs: RowDef[];
	columnDefs: ColDef[];
}
