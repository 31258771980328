export class DateFormatter {
	public static formatDate(date: Date, locales?: string | string[], options?: any): string {
		return date.toLocaleDateString(locales, options);
	}

	public static formatTime(date: Date, locales?: string | string[], options?: any): string {
		return date.toLocaleTimeString(locales, options);
	}

	public static getOrdinaltoDisplay(day: number): string {
		if (day >= 11 && day <= 13) {
			return 'th';
		}
		switch (day % 10) {
			case 1: return 'st';
			case 2: return 'nd';
			case 3: return 'rd';
			default: return 'th';
		}
	}
}
