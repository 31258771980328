import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
	selector: 'pipelines-date-renderer',
	templateUrl: './date-renderer.component.html',
	styleUrls: ['./date-renderer.component.scss']
})
export class DateRendererComponent implements AgRendererComponent {

	public date: string;
	public time: string;
	private removeLeadingZeroRegex = /^[0:]+(?=\d[\d:]{3})/;

	public agInit(params: ICellRendererParams): void {
		const dateTime = this.getDateTime(params);
		this.date = this.formatDate(dateTime);
		this.time = this.formatTime(dateTime);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public refresh(params: ICellRendererParams): boolean {
		return false;
	}

	private getDateTime(params: ICellRendererParams): Date {
		const value = params.valueFormatted ? params.valueFormatted : params.value;
		return new Date(value);
	}

	private formatDate(date: Date): string {
		return date.toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' });
	}

	private formatTime(date: Date): string {
		return date
			.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })
			.replace(this.removeLeadingZeroRegex, '');
	}
}
