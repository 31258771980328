<div class="flex flex-row justify-between text-sm uppercase mb-1.5 text-gray-500">
	<span>{{text}}:</span>
	<span *ngIf="!subtext; else blockDisplay">{{date}}</span>
	<ng-template #blockDisplay>
		<div class="flex flex-col justify-end">
			<span class="ml-auto">{{date}}</span>
			<span class="text-xs italic {{subtextColorClass}}">{{subtext}}</span>
		</div>
	</ng-template>
</div>
