import { Component } from '@angular/core';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';

@Component({
	selector: 'pipelines-logout',
	template: ``,
})
export class LogoutComponent {

	constructor(private authenticationUtility: AuthenticationUtility) {
		this.authenticationUtility.logout();
	}
}
