import { PipelineEvent } from '@pipelines/app/models/requests/intents/pipeline-event';
import { KnownEvents } from '@pipelines/app/models/requests/intents/known-events';

export class RetrieveReviewReasonsEvent extends PipelineEvent {

	public templateName: string;

	constructor() {
		super();
		this.name = KnownEvents.retrieveReviewReasons;
	}
}
