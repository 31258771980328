import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GridReadyEvent } from '@pipelines/app/models/grid-ready.interface';
import { ApplicationService } from '@pipelines/app/services/application.service';
import { DocumentsUploadedRendererComponent } from '@pipelines/app/components/grid/renderers/documents-uploaded-renderer/documents-uploaded-renderer.component';
import { ActionsRendererComponent } from '@pipelines/app/components/grid/renderers/actions-renderer/actions-renderer.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { GridDatasource } from '@pipelines/app/components/grid/datasource/grid-datasource';
import { ApplicantRendererComponent } from '@pipelines/app/components/grid/renderers/applicant-renderer/applicant-renderer.component';
import { DateRendererComponent } from '@pipelines/app/components/grid/renderers/date-renderer/date-renderer.component';
import { ColumnMenuTabs } from '@pipelines/app/components/grid/column-menu-tabs.enum';
import { filter, first, map, startWith, takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { ApplicationTypeRendererComponent } from '@pipelines/app/components/grid/renderers/application-type-renderer/application-type-renderer.component';
import { StatusRendererComponent } from '@pipelines/app/components/grid/renderers/status-renderer/status-renderer.component';
import { ColumnName } from '@pipelines/app/components/grid/column-names.consts';
import {
	filterApplicationTypeMap,
	filterDocumentsUploadedMap,
	filterStatusMap,
} from '@pipelines/app/components/grid/filter-map';
import { KnownEvents } from '@pipelines/app/models/requests/intents/known-events';
import {
	toAgGridFilterInstance,
	toBlueFinSetFilter,
	toBlueFinTextFilter,
} from '@pipelines/app/components/grid/filter.util';

@Component({
	selector: 'pipelines-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, OnDestroy {
	@ViewChild('searchInput') public searchInput: ElementRef<HTMLInputElement>;
	public gridOptions: GridOptions;
	public totalRows: number;
	public pageSizeOptions: number[] = [25, 50, 100, 150, 200];
	public pageSize: number;
	public searchText: string;
	public columnDefs$: Observable<ColDef[]>;
	public isResetViewDisabled: boolean;
	private hasOverlay: boolean = false;
	private destroy$: Subject<void> = new Subject<void>();
	private queryParams: Params;
	private isInitialLoad = true;

	private gridApi: GridApi;

	private applicationService = inject(ApplicationService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private gridDatasource = inject(GridDatasource);

	public ngOnInit(): void {
		this.setupQueryParamsSubscriptions();
		this.initColumnDefs();
		this.setGridOptions();
		this.setupRefreshSubscription();
	}

	public ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onGridReady({ api }: GridReadyEvent): void {
		this.gridApi = api;
	}

	public onGridUpdated(): void {
		if (_.isNil(this.gridApi)) {
			this.isResetViewDisabled = true;
		} else {
			this.isResetViewDisabled =
				_.isEmpty(this.gridApi.getFilterModel()) && !this.searchText && !this.queryParams['sortModel'];
		}
		this.totalRows = this.gridApi?.getDisplayedRowCount() ?? 0;
		if (this.totalRows > 0 && this.isInitialLoad) {
			this.setInitialFilterInstances();
			this.isInitialLoad = false;
		}
		this.gridApi?.autoSizeAllColumns();
	}

	public onSearch(): void {
		const searchInputValue = this.searchInput.nativeElement.value.trim();
		this.addQueryParams({ searchText: searchInputValue.length > 0 ? searchInputValue : null });
		this.gridApi.refreshServerSide({ purge: true });
	}

	public onFilterChanged(): void {
		const filterModel = this.modifyOutgoingFilter();
		this.addQueryParams({ filterModel: JSON.stringify(filterModel) });
	}

	public onSortChanged(): void {
		let sortModel = null;
		const sortedColumn = _.find(this.gridApi.getColumnState(), (column) => column.sort !== null);
		if (sortedColumn) {
			const sortObject = _.pick(sortedColumn, ['colId', 'sort']);
			sortModel = JSON.stringify([sortObject]);
		}
		this.addQueryParams({ sortModel });
	}

	public setupRefreshSubscription(): void {
		this.applicationService
			.getProcessingApplicationEvent$()
			.pipe(
				filter((evt) => evt?.eventName === KnownEvents.withdraw),
				takeUntil(this.destroy$),
			)
			.subscribe((processingApplicationEvent) => {
				if (processingApplicationEvent.isProcessing) {
					this.hasOverlay = true;
					this.gridApi.showLoadingOverlay();
				} else if (this.hasOverlay && !processingApplicationEvent.isProcessing) {
					this.gridApi.hideOverlay();
					this.gridApi.refreshServerSide({ purge: true });
					this.hasOverlay = false;
				}
			});
	}

	public setupQueryParamsSubscriptions(): void {
		this.route.queryParams.subscribe((queryParams) => {
			this.queryParams = queryParams;
			this.searchText = queryParams['searchText'] ?? '';
			this.pageSize = queryParams['pageSize'] ?? this.pageSizeOptions[0];
		});
	}

	public initColumnDefs(): void {
		this.columnDefs$ = this.gridDatasource.columnDefs$.pipe(
			first((columnDefs) => columnDefs.length > 0),
			map((columnDefinitions) =>
				columnDefinitions?.filter((columnDefinition) => columnDefinition.cellRenderer !== 'hiddenRenderer'),
			),
			startWith([] as ColDef[]),
		);
	}

	public onResetView(): void {
		this.gridApi.resetColumnState();
		this.gridApi.setFilterModel(null);
		this.router.navigate(['/'], {
			relativeTo: this.route,
			queryParams: { searchText: null, sortModel: null, pageSize: null },
			queryParamsHandling: 'merge',
		});
		this.gridApi.refreshServerSide({ purge: true });
	}

	private addQueryParams(queryParams: any): void {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams,
			queryParamsHandling: 'merge',
		});
	}

	private modifyOutgoingFilter(): { [key: string]: any } {
		const filterModel = this.gridApi.getFilterModel();

		toBlueFinSetFilter(filterModel, ColumnName.documentsUploaded, filterDocumentsUploadedMap);
		toBlueFinTextFilter(filterModel, ColumnName.applicationType, filterApplicationTypeMap);
		toBlueFinTextFilter(filterModel, ColumnName.status, filterStatusMap);

		return filterModel;
	}

	private setInitialFilterInstances(): void {
		const filterModel = JSON.parse(this.queryParams['filterModel'] ?? null);
		if (_.isEmpty(filterModel)) {
			return;
		}

		for (const [key, value] of Object.entries(filterModel)) {
			const filterInstance = this.gridApi.getFilterModel()[key];
			if (filterInstance) {
				if ([ColumnName.applicationType, ColumnName.status, ColumnName.documentsUploaded].includes(key)) {
					filterInstance.setModel(toAgGridFilterInstance(key, value));
				} else {
					filterInstance.setModel(value);
				}
			}
		}
	}

	private setGridOptions(): void {
		this.gridOptions = {
			rowModelType: 'serverSide',
			serverSideDatasource: this.gridDatasource,
			cacheBlockSize: this.pageSize,
			components: {
				actionsRenderer: ActionsRendererComponent,
				applicantRenderer: ApplicantRendererComponent,
				dateRenderer: DateRendererComponent,
				documentsUploadedRenderer: DocumentsUploadedRendererComponent,
				statusRenderer: StatusRendererComponent,
				applicationTypeRenderer: ApplicationTypeRendererComponent,
			},
			defaultColDef: {
				flex: 1,
				minWidth: 100,
				sortable: false,
				lockPosition: true,
				resizable: true,
				menuTabs: [ColumnMenuTabs.filter],
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				},
			},
			pagination: true,
			paginationPageSize: this.pageSize,
			paginationPageSizeSelector: this.pageSizeOptions,
			onSortChanged: this.onSortChanged.bind(this),
			onFilterChanged: this.onFilterChanged.bind(this),
			onModelUpdated: this.onGridUpdated.bind(this),
			onGridReady: this.onGridReady.bind(this),
		};
	}
}
