import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { WithdrawApplicationDialogComponent } from '@pipelines/app/components/grid/renderers/actions-renderer/withdraw-application-dialog/withdraw-application-dialog.component';
import { TerminalPhases } from '@common/lib/constants/terminal-phases';
import { RowDef } from '@pipelines/app/models/row-def';
import { StatusEntry } from '@pipelines/app/models/status-entry';
import { ArchiveUtil } from '@pipelines/app/components/grid/archive-util';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';

@Component({
	selector: 'pipelines-actions-renderer',
	templateUrl: './actions-renderer.component.html',
	styleUrls: ['./actions-renderer.component.scss'],
})
export class ActionsRendererComponent implements AgRendererComponent {
	public mailTo: string;
	public disableWithdraw: boolean;
	public status: string;
	public statusHistory: StatusEntry[];
	public rowData: RowDef;
	public isPendingArchival: boolean;
	public onMenuOpenedEvent: Subject<void> = new Subject<void>();
	private readonly nearingArchivalDays = 5;

	constructor(
		private readonly clipboard: Clipboard,
		private readonly snackBar: MatSnackBar,
		private readonly dialog: MatDialog,
	) {}

	public agInit(params: ICellRendererParams): void {
		this.rowData = params.data;
		this.mailTo = `mailto:${this.rowData.primaryUserEmail}`;
		this.status = this.getCurrentStatus();
		this.disableWithdraw = this.withdrawDisabled();
		this.isPendingArchival = this.getIsPendingArchival();
		this.statusHistory = this.rowData.rowSupplement.statusHistory;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public refresh(params: ICellRendererParams): boolean {
		return false;
	}

	public onCopyAppId(): void {
		this.clipboard.copy(this.rowData.applicationId);
		this.snackBar.open('App ID Copied', null, { duration: 1250 });
	}

	public onGoToAlloy(): void {
		window.open(this.rowData.originationUrl, '_blank', 'noopener');
	}

	public onWithdrawApplication(): void {
		this.dialog.open(WithdrawApplicationDialogComponent, {
			panelClass: 'dialog-container',
			height: '60%',
			width: '22%',
			data: {
				applicationId: this.rowData.applicationId,
				createdDate: this.rowData.createdDate,
				userEmail: this.rowData.primaryUserEmail,
			},
		});
	}

	public emitMenuOpened(): void {
		this.onMenuOpenedEvent.next();
	}

	private getCurrentStatus(): string {
		const statusHistory = this.rowData.rowSupplement.statusHistory;
		if (statusHistory) {
			const sortedHistory = _.orderBy(this.rowData.rowSupplement.statusHistory, 'updateDate', 'desc');
			return sortedHistory[0].name;
		}

		return this.rowData.status;
	}

	private withdrawDisabled(): boolean {
		return TerminalPhases.indexOf(this.status as knownApplicationStatus) !== -1;
	}

	private getIsPendingArchival(): boolean {
		const applicationStatus =
			_.orderBy(this.rowData.rowSupplement.statusHistory, 'updateDate', 'desc')[0]?.name ?? this.rowData.status;

		if (TerminalPhases.includes(applicationStatus as knownApplicationStatus)) {
			return false;
		}

		if (this.rowData.rowSupplement.archivalDate) {
			const archivalDate = new Date(this.rowData.rowSupplement.archivalDate);
			return ArchiveUtil.getDaysUntilArchival(archivalDate) <= this.nearingArchivalDays;
		}

		return false;
	}
}
