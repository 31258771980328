import { Component, Input } from '@angular/core';
import { WithdrawData } from '@pipelines/app/models/withdraw-data';

@Component({
	selector: 'pipelines-withdrawn-comment',
	templateUrl: './withdrawn-comment.component.html',
	styleUrls: ['./withdrawn-comment.component.scss']
})
export class WithdrawnCommentComponent {

	@Input() public withdrawData: WithdrawData;

	constructor() {
	}

}
