import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientBase, PossibleHeaders } from '@pipelines/app/clients/client.base';
import { PipelinesConfiguration } from '@pipelines/app/pipelines.configuration';
import { ProcessApplicationRequest } from '@pipelines/app/models/requests/process-application-request';
import { ProcessApplicationResponseResource } from '@pipelines/app/models/resource/process-application-response.resource';

@Injectable({
	providedIn: 'root',
})
export class PipelinesClient extends ClientBase {
	public clientBaseUrl: string;

	constructor(
		private readonly http: HttpClient,
		private readonly config: PipelinesConfiguration,
	) {
		super(http);
		this.clientBaseUrl = this.config.experienceApi;
	}

	public processApplication$(
		processApplicationRequest: ProcessApplicationRequest,
	): Observable<ProcessApplicationResponseResource> {
		return this.post<any>('/pipeline/process', processApplicationRequest).pipe(
			catchError((err) => this.createError(err)),
		);
	}

	public onAddHeaders(): PossibleHeaders | void {
		return new HttpHeaders({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'Content-Type': 'application/json',
		});
	}
}
