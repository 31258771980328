<div class="w-64 leftnav fixed top-0 bottom-0 left-0">
	<div class="flex justify-center pt-4 pb-4">
		<img class="h-8" alt="nbkc logo" src="/assets/img/logo-w.svg">
	</div>
	<div class="navigation-container flex items-start pl-4 text-base cursor-pointer">
		<div class="text-white p-4" [routerLink]="['/']">
			<span class="fal fa-poll-h mr-2"></span>Application Queue
		</div>
	</div>
</div>
