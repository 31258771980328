<div class="mt-2.5">
	<div class="flex flex-row items-center">
		<i class="mr-2 text-red-600 far fa-exclamation-square"></i>
		<span class="font-bold">Missing Fields</span>
		<button (click)="onGetReviewReasons($event)" class="ml-auto">
			<i class="fal fa-sync fa-xs refresh-spinner" [ngClass]="{ 'fa-spin': isRefreshing }"></i>
		</button>
	</div>
	<ng-container *ngIf="!isRefreshing">
		<div *ngIf="reviewReasons && reviewReasons.length > 0; else noMissingFields">
			<ul class="list-disc list-outside ml-3">
				<li *ngFor="let reviewReason of reviewReasons.slice(0, maxReviewReasonsToDisplay)" class="text-xs my-1.5">
					{{reviewReason}}
				</li>
			</ul>
			<span *ngIf="reviewReasons.length > maxReviewReasonsToDisplay" class="text-xs text-gray-500">
				+ {{reviewReasons.length - maxReviewReasonsToDisplay}} other missing fields.
			</span>
		</div>
		<ng-template #noMissingFields>
			<div class="uppercase text-center text-sm mt-1">No Missing Fields</div>
		</ng-template>
	</ng-container>
</div>
