import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RowDef } from '@pipelines/app/models/row-def';

@Component({
	selector: 'pipelines-applicant-renderer',
	templateUrl: './applicant-renderer.component.html',
	styleUrls: ['./applicant-renderer.component.scss']
})
export class ApplicantRendererComponent implements AgRendererComponent {

	public rowData: RowDef;
	public cellValue: string;
	public applicationType: string;
	public primaryApplicant: string;

	public agInit(params: ICellRendererParams): void {
		this.rowData = params.data;
		this.applicationType = params.data.applicationType;
		this.primaryApplicant = params.valueFormatted ? params.valueFormatted : params.value;
		this.cellValue = this.getValueToDisplay();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public refresh(params: ICellRendererParams): boolean {
		return false;
	}

	private getValueToDisplay(): string {
		if (this.applicationType === 'Business') {
			return this.rowData.doingBusinessAsName;
		}

		return this.primaryApplicant;
	}
}
