import { Component, Input, OnInit } from '@angular/core';
import { ApplicationService } from '@pipelines/app/services/application.service';
import { RetrieveReviewReasonsEvent } from '@pipelines/app/models/requests/intents/retrieve-review-reasons-event';
import { applicationType } from '@common/lib/constants/application-types';
import { RetrieveReviewReasonsResult } from '@pipelines/app/models/update-results/retrieve-review-reasons-result';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
	selector: 'pipelines-review-reasons',
	templateUrl: './review-reasons.component.html',
	styleUrls: ['./review-reasons.component.scss']
})
export class ReviewReasonsComponent implements OnInit {

	@Input() public reviewReasons: string[];
	@Input() public onMenuOpenedEvent: Observable<void>;
	@Input() public applicationType: string;
	@Input() public applicationId: string;
	public maxReviewReasonsToDisplay = 3;
	public isRefreshing: boolean = true;

	constructor(private readonly pipelinesManager: ApplicationService) {
	}

	ngOnInit(): void {
		this.onMenuOpenedEvent.subscribe(() => {
			this.onGetReviewReasons(null);
		});
	}

	public onGetReviewReasons(evt?: MouseEvent) {
		evt?.stopPropagation();

		this.isRefreshing = true;

		const retrieveReviewReasonsEvent = new RetrieveReviewReasonsEvent();
		retrieveReviewReasonsEvent.templateName = `deposits-${this.applicationType === applicationType.Individual ? 'personal' : 'business'}`;

		this.pipelinesManager.processApplication$({
			applicationId: this.applicationId,
			event: retrieveReviewReasonsEvent
		}).pipe(
			filter((response) => response !== null),
			take(1)
		).subscribe((response) => {
			this.reviewReasons = (response.result as RetrieveReviewReasonsResult)?.reviewReasons;
			this.isRefreshing = false;
		});
	}
}
