import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlueFinCommonModule } from '@common/public-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GridComponent } from './components/grid/grid.component';
import { LeftNavComponent } from './components/left-nav/left-nav.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { startupFactory } from '@pipelines/app/app.startup';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ActionsRendererComponent } from './components/grid/renderers/actions-renderer/actions-renderer.component';
import { ApplicantRendererComponent } from './components/grid/renderers/applicant-renderer/applicant-renderer.component';
import { DocumentsUploadedRendererComponent } from './components/grid/renderers/documents-uploaded-renderer/documents-uploaded-renderer.component';
import { DateRendererComponent } from './components/grid/renderers/date-renderer/date-renderer.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import 'ag-grid-enterprise';
import { AuthenticationInterceptor } from '@common/lib/utilities/authentication/interceptors/authentication.interceptor';
import { CommonConfiguration } from '@common/lib/utilities/configuration/common.configuration';
import { PipelinesConfiguration } from '@pipelines/app/pipelines.configuration';
import { LoginComponent } from '@pipelines/app/components/login/login.component';
import { LogoutComponent } from '@pipelines/app/components/logout/logout.component';
import { StatusRendererComponent } from './components/grid/renderers/status-renderer/status-renderer.component';
import { ApplicationTypeRendererComponent } from './components/grid/renderers/application-type-renderer/application-type-renderer.component';
import { WithdrawApplicationDialogComponent } from './components/grid/renderers/actions-renderer/withdraw-application-dialog/withdraw-application-dialog.component';
import { ActionsInfoMenuComponent } from './components/grid/renderers/actions-renderer/actions-info-menu/actions-info-menu.component';
import { StatusDisplayComponent } from './components/grid/renderers/actions-renderer/actions-info-menu/status-display/status-display.component';
import { ReviewReasonsComponent } from './components/grid/renderers/actions-renderer/actions-info-menu/review-reasons/review-reasons.component';
import { WithdrawnCommentComponent } from './components/grid/renderers/actions-renderer/actions-info-menu/withdrawn-comment/withdrawn-comment.component';
import { StatusDisplayContainerComponent } from './components/grid/renderers/actions-renderer/actions-info-menu/status-display-container/status-display-container.component';
import { CommonErrorService } from '@common/lib/services/common-error.service';
import { ErrorService } from './services/error.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
	declarations: [
		AppComponent,
		LeftNavComponent,
		GridComponent,
		ActionsRendererComponent,
		ApplicantRendererComponent,
		DocumentsUploadedRendererComponent,
		DateRendererComponent,
		LoginComponent,
		LogoutComponent,
		StatusRendererComponent,
		ApplicationTypeRendererComponent,
		WithdrawApplicationDialogComponent,
		ActionsInfoMenuComponent,
		StatusDisplayComponent,
		ReviewReasonsComponent,
		WithdrawnCommentComponent,
		StatusDisplayContainerComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		BlueFinCommonModule,
		AgGridModule,
		ReactiveFormsModule,
		FormsModule,
		MatTabsModule,
		MatPaginatorModule,
		MatMenuModule,
		MatButtonToggleModule,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: startupFactory,
			deps: [Injector],
			multi: true,
		},
		{
			provide: CommonErrorService,
			useExisting: ErrorService,
		},
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthenticationInterceptor,
			multi: true,
		},
		{
			provide: CommonConfiguration,
			useExisting: PipelinesConfiguration,
		},
		{
			//In Angular 15+ this option allows form field options to nestcle closer together. A new div was added and by
			//default it takes up space under the control. This makes it to where it only show when active.
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				subscriptSizing: 'dynamic',
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
