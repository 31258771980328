import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { RowDef } from '@pipelines/app/models/row-def';
import { GridRowResource } from '@pipelines/app/models/resource/grid-row.resource';

export class GridRowResourceToRowDefAdapter extends AdapterBase<GridRowResource, RowDef> {
	adapt(source: GridRowResource): RowDef {
		if (!source) {
			return null;
		}

		const rowData = new RowDef();
		for (const key of Object.keys(source)) {
			rowData[key] = source[key];
		}
		return rowData;
	}
}
