import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { RowDef } from '@pipelines/app/models/row-def';
import { GridRow } from '@pipelines/app/models/resource/grid.row';

export class GridRowToRowDefAdapter extends AdapterBase<GridRow, RowDef> {
	adapt(source: GridRow): RowDef {
		if (!source) {
			return null;
		}

		const rowData = new RowDef();
		for (const key of Object.keys(source)) {
			rowData[key] = source[key];
		}
		return rowData;
	}
}
