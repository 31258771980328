import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { GridColumnResource } from '@pipelines/app/models/resource/grid-column.resource';
import { ColDef } from 'ag-grid-community';

export class GridColumnResourceToColDefAdapter extends AdapterBase<GridColumnResource, ColDef> {

	public adapt(source: GridColumnResource): ColDef {
		const destination = {} as ColDef;

		if (source) {
			destination.headerName = source.headerName;
			destination.filter = source.filter;
			destination.field = source.field;
			destination.cellRenderer = source.cellRenderer;

			if (source.filter && source.filterParams) {
				destination.filterParams = source.filterParams;
			}

			if (source.options) {
				for (const option of source.options) {
					destination[option.name] = option.value;
				}
			}
		}

		return destination;
	}
}
