import { PipelineEvent } from '@pipelines/app/models/requests/intents/pipeline-event';
import { KnownEvents } from '@pipelines/app/models/requests/intents/known-events';

export class WithdrawEvent extends PipelineEvent {

	public notes: string[];

	constructor() {
		super();
		this.name = KnownEvents.withdraw;
	}
}
