import { Component } from '@angular/core';
import { applicationType } from '@common/lib/constants/application-types';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'pipelines-application-type-renderer',
  templateUrl: './application-type-renderer.component.html',
  styleUrls: ['./application-type-renderer.component.scss']
})
export class ApplicationTypeRendererComponent implements AgRendererComponent{

	public cellValue: string;
	public applicationType: string;
	public applicationTypeIcon: string;
	private commonIconClasses = 'mr-2';

	public agInit(params: ICellRendererParams): void {
		this.applicationType = params.data.applicationType;
		this.applicationTypeIcon = this.getApplicationTypeIcon();
		this.cellValue = this.getDisplayValue();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public refresh(params: ICellRendererParams): boolean {
		return false;
	}

	public getApplicationTypeIcon(): string {
		switch (this.applicationType) {
			case applicationType.Individual:
				return `${this.commonIconClasses} far fa-user`;
			case applicationType.Business:
				return `${this.commonIconClasses} far fa-building`;
		}
	}

	private getDisplayValue(): string {
		switch (this.applicationType) {
			case applicationType.Individual:
				return 'Consumer';
			case applicationType.Business:
				return applicationType.Business;
		}
	}
}
