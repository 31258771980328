import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ProcessApplicationRequest } from '@pipelines/app/models/requests/process-application-request';
import { PipelinesClient } from '@pipelines/app/clients/pipelines.client';
import { ProcessingApplicationEvent } from '@pipelines/app/models/processing-application-event';
import {
	ProcessApplicationResponseResource
} from '@pipelines/app/models/resource/process-application-response.resource';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ApplicationService {
	currentApplicationEvent: BehaviorSubject<ProcessingApplicationEvent> = new BehaviorSubject(null);

	constructor(
		private readonly pipelinesClient: PipelinesClient
	) {}

	public processApplication$(processApplicationRequest: ProcessApplicationRequest): Observable<ProcessApplicationResponseResource> {
		return this.pipelinesClient.processApplication$(processApplicationRequest)
			.pipe(catchError((err) => {
				this.setProcessingApplicationEvent({
					isProcessing: false,
					eventName: processApplicationRequest.event.name
				});
				return throwError(err);
			}));
	}

	public setProcessingApplicationEvent(processingApplicationEvent: ProcessingApplicationEvent): void {
		this.currentApplicationEvent.next(processingApplicationEvent);
	}

	public getProcessingApplicationEvent$(): Observable<ProcessingApplicationEvent> {
		return this.currentApplicationEvent;
	}
}
