import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { PipelinesStateResponseResource } from '@pipelines/app/models/resource/pipelines-state-response.resource';
import { GridColumnResourceToColDefAdapter } from '@pipelines/app/adapters/grid-column-resource-to-col-def.adapter';
import {
	GridFilterResourceToGridFilterAdapter
} from '@pipelines/app/adapters/grid-filter-resource-to-grid-filter.adapter';
import { GridData } from '@pipelines/app/models/grid-data';
import { GridRowResourceToRowDefAdapter } from '@pipelines/app/adapters/grid-row-resource-to-row-def.adapter';

const gridColumnResourceToColDefAdapter = new GridColumnResourceToColDefAdapter();
const gridFilterResourceToGridFilterAdapter = new GridFilterResourceToGridFilterAdapter();
const gridRowResourceToRowDefAdapter = new GridRowResourceToRowDefAdapter();

export class PipelinesStateResponseResourceToGridDataAdapter extends AdapterBase<PipelinesStateResponseResource, GridData> {

	public adapt(source: PipelinesStateResponseResource): GridData {
		const destination = new GridData();

		if (source) {
			destination.columnDefs = source.columns ? gridColumnResourceToColDefAdapter.adaptCollection(source.columns) : [];
			destination.rowDefs = gridRowResourceToRowDefAdapter.adaptCollection(source.rows);
			destination.selectedFilters = source.selectedFilters ? gridFilterResourceToGridFilterAdapter.adaptCollection(source.selectedFilters) : [];
			destination.availableFilters = source.availableFilters ? gridFilterResourceToGridFilterAdapter.adaptCollection(source.availableFilters) : [];
			destination.totalItems = source.totalItems;
			destination.totalPages = source.totalPages;
		}

		return destination;
	}
}

