import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ErrorTypes } from '@common/lib/models/enum/error-types.enum';
import { ProviderError } from '@common/lib/models/provider-error.model';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class ClientBase {

	public abstract clientBaseUrl: string;

	protected constructor(private httpClient: HttpClient) {
	}

	protected get<TResponse>(endPointPath: string): Observable<TResponse> {
		const options = this.buildRequestOptions();
		return this.httpClient
			.get<TResponse>(`${this.clientBaseUrl}${endPointPath}`, options)
			.pipe(
				map((response: any) => {
					this.onResponse<HttpResponse<TResponse>>(response);
					return response.body;
				})
			);
	}

	protected post<TResponse>(endPointPath: string, postData: any): Observable<TResponse> {
		const options = this.buildRequestOptions();
		return this.httpClient
			.post<TResponse>(`${this.clientBaseUrl}${endPointPath}`, postData, options)
			.pipe(
				map((response: any) => {
					this.onResponse<HttpResponse<TResponse>>(response);
					return response.body;
				})
			);
	}

	protected onAddHeaders(): PossibleHeaders | void {
		// Intentionally Blank
	}

	protected onAddParams(): PossibleParams | void {
		// Intentionally Blank
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected onResponse<T>(response: HttpResponse<T>): void {
		// Intentionally Blank
	}

	protected createError(error: HttpErrorResponse): Observable<never> {
		const message = error.message;
		let errorType: ErrorTypes;

		switch (error.status) {
			case 400:
				errorType = ErrorTypes.recoverable;
				break;
			case 401:
				errorType = ErrorTypes.unauthorized;
				break;
			case 403:
				errorType = ErrorTypes.fatal;
				break;
			case 408:
				errorType = ErrorTypes.recoverable;
				break;
			case 422:
				errorType = ErrorTypes.fatal;
				break;
			case 500:
				errorType = ErrorTypes.fatal;
				break;
			default:
				errorType = ErrorTypes.unknown;
		}
		const providerError = new ProviderError(errorType, message, error);

		return throwError(providerError);
	}

	private buildRequestOptions(): RequestOptions {
		const options: RequestOptions = { };
		options.headers = this.onAddHeaders() || null;
		options.params = this.onAddParams() || null;
		options.observe = 'response' as const;
		return options;
	}
}

interface RequestOptions {
	headers?: PossibleHeaders;
	observe?: any;
	params?: PossibleParams;
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
}

export type PossibleHeaders = HttpHeaders | {
	[header: string]: string | string[];
};

export type PossibleParams = HttpParams | {
	[param: string]: string | string[];
};
