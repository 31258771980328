<div class="withdraw-dialog">
	<div class="h-full withdraw-content">
		<div class="flex flex-col">
			<h6 class="font-bold text-2xl">Are you sure you want to withdraw this application?</h6>
			<p class="subheader text-lg">Once you withdraw this application, the user will not be able to access or continue this application.</p>
		</div>
		<div class="flex flex-col my-2">
			<div>
				<span class="font-extrabold text-xs">Created:</span><span class="font-normal text-xs">&nbsp;{{createdDate}} at {{createdTime}}</span>
			</div>
			<div>
				<span class="font-extrabold text-xs">User:</span><span class="font-normal text-xs">&nbsp;{{email}}</span>
			</div>
		</div>
		<div class="form-text">
			<mat-form-field class="w-full" appearance="outline">
				<mat-label>Comment*</mat-label>
				<textarea class="mb-2 resize-y" matInput [(ngModel)]="commentText"></textarea>
			</mat-form-field>
			<div class="mx-4 -mt-4">
				<p class="text-xs text-gray-500">Provide the reason for why this application was withdrawn.</p>
			</div>
		</div>
	</div>
	<div class="flex justify-between bottom-0 actions">
		<button mat-button [mat-dialog-close]="true" class="cancel-button text-base font-light">Cancel</button>
		<button [disabled]="!commentText"
				color="primary"
				[ngClass]="{ 'cursor-not-allowed': !commentText }"
				[mat-dialog-close]="true"
				mat-raised-button  class="rounded-full text-white text-base font-light"
				(click)="onWithdrawApplication()">
			Withdraw
		</button>
	</div>
</div>
