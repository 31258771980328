import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { GetRowsRequest } from '@pipelines/app/models/requests/get-rows-request';
import { SortModel } from '@pipelines/app/models/sort-model.interface';

export class ServerSideGetRowsRequestToGetRowsRequestAdapter extends AdapterBase<IServerSideGetRowsRequest, GetRowsRequest> {

	public adapt(source: IServerSideGetRowsRequest, options?: ServerSideGetRowsRequestToGetRowsRequestOptions): GetRowsRequest {
		const destination = new GetRowsRequest();

		if (source) {
			destination.startRow = source.startRow;
			destination.endRow = source.endRow;
			destination.searchText = options?.searchText;
			destination.filterModel = options?.filterModel ? options.filterModel : source.filterModel;
			destination.sortModel = options?.sortModel ? options.sortModel : source.sortModel;
		}

		return destination;
	}
}

interface ServerSideGetRowsRequestToGetRowsRequestOptions {
	searchText?: string;
	filterModel?: Record<string, any>;
	sortModel?: SortModel[];
}
