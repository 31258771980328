import { applicationType as appType } from '@common/lib/constants/application-types';

export class ArchiveUtil {

	private static msPerDay = 86400000;

	private static PersonalApplication = {
		preSubmitArchiveDelay: 86400,
		postSubmitArchiveDelay: 43200
	};

	private static BusinessApplication = {
		preSubmitArchiveDelay: 86400,
		postSubmitArchiveDelay: 108000
	};

	public static getDaysUntilArchival(futureArchivalDate: Date): number {
		return Math.ceil((futureArchivalDate.getTime() - new Date().getTime()) / this.msPerDay);
	}

	public static getArchiveMinutes(applicationType: string, isPreSubmit: boolean): number {
		if (applicationType === appType.Individual) {
			return isPreSubmit
				? this.PersonalApplication.preSubmitArchiveDelay
				: this.PersonalApplication.postSubmitArchiveDelay;
		} else {
			return isPreSubmit
				? this.BusinessApplication.preSubmitArchiveDelay
				: this.BusinessApplication.postSubmitArchiveDelay;
		}
	}
}
