import { Injectable } from '@angular/core';
import { CommonErrorService } from '@common/lib/services/common-error.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorService implements CommonErrorService {
	constructor() {}

	public handleError() {

	}
}
