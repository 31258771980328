import { GridDataResponse } from '@pipelines/app/models/responses/grid-data.response';
import { GridData } from '@pipelines/app/models/grid-data';
import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { GridColumnToColDefAdapter } from '@pipelines/app/adapters/grid-column-to-col-def.adapter';
import { ApiGridFilterToGridFilterAdapter } from '@pipelines/app/adapters/api-grid-filter-to-grid-filter.adapter';
import { GridRowToRowDefAdapter } from '@pipelines/app/adapters/grid-row-to-row-def.adapter';

const gridColumnToColDefAdapter = new GridColumnToColDefAdapter();
const apiGridFilterToGridFilterAdapter = new ApiGridFilterToGridFilterAdapter();
const gridRowToRowDefAdapter = new GridRowToRowDefAdapter();

export class GridDataResponseToGridDataAdapter extends AdapterBase<GridDataResponse, GridData> {
	adapt(source: GridDataResponse): GridData {
		const destination = new GridData();

		if (source) {
			destination.columnDefs = source.columns ? gridColumnToColDefAdapter.adaptCollection(source.columns) : [];
			destination.rowDefs = gridRowToRowDefAdapter.adaptCollection(source.rows);
			destination.selectedFilters = source.selectedFilters
				? apiGridFilterToGridFilterAdapter.adaptCollection(source.selectedFilters)
				: [];
			destination.availableFilters = source.availableFilters
				? apiGridFilterToGridFilterAdapter.adaptCollection(source.availableFilters)
				: [];
			destination.totalItems = source.totalItems;
			destination.totalPages = source.totalPages;
		}

		return destination;
	}
}
