<div class="leading-4">
	<span *ngIf="numDocuments <= 0; else documents" class="font-semibold">
		NO DOCUMENTS
	</span>
	<ng-template #documents>
		<div>
			<i *ngFor="let item of [].constructor(numDocuments > maxDocumentIconsToRender ? maxDocumentIconsToRender : numDocuments)"
			   class="fal fa-file-check text-xl mr-3">
			</i>
			<span *ngIf="numDocuments > maxDocumentIconsToRender" class="font-semibold">+ {{numDocuments - maxDocumentIconsToRender}}</span>
		</div>
		<span class="opacity-50 text-xs">{{lastDocumentUploadDate}}</span>
	</ng-template>
</div>

