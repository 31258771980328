import { Injectable } from '@angular/core';
import { CommonConfiguration } from '@common/lib/utilities/configuration/common.configuration';

@Injectable({
	providedIn: 'root'
})
export abstract class PipelinesConfiguration extends CommonConfiguration {
	public experienceApi: string;
	public agGridLicenseKey: string;
}
