import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	WithdrawApplicationDialogData
} from '@pipelines/app/components/grid/renderers/actions-renderer/withdraw-application-dialog/withdraw-application.data.model';
import { DateFormatter } from '@common/lib/utilities/date-formatter.utility';
import { ApplicationService } from '@pipelines/app/services/application.service';
import { WithdrawEvent } from '@pipelines/app/models/requests/intents/withdraw-event';
import { filter, take } from 'rxjs/operators';
import { KnownEvents } from '@pipelines/app/models/requests/intents/known-events';

@Component({
	selector: 'pipelines-withdraw-application-dialog',
	templateUrl: './withdraw-application-dialog.component.html',
	styleUrls: ['./withdraw-application-dialog.component.scss']
})
export class WithdrawApplicationDialogComponent implements OnInit {

	public applicationId: string;
	public email: string;
	public createdDate: string;
	public createdTime: string;
	public commentText = '';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: WithdrawApplicationDialogData,
		private readonly pipelinesManager: ApplicationService
	) { }

	ngOnInit(): void {
		this.createdDate = this.getFormattedDate();
		this.createdTime = this.getFormattedTime();
		this.applicationId = this.data.applicationId;
		this.email = this.data.userEmail;
	}

	public onWithdrawApplication(): void {
		const withdrawEvent = new WithdrawEvent();
		withdrawEvent.notes = [this.commentText];

		this.pipelinesManager.setProcessingApplicationEvent({
			isProcessing: true,
			eventName: KnownEvents.withdraw
		});

		this.pipelinesManager.processApplication$({
			applicationId: this.applicationId,
			event: withdrawEvent
		}).pipe(
			filter((response) => response !== null),
			take(1)
		).subscribe(() => {
			this.pipelinesManager.setProcessingApplicationEvent({
				isProcessing: false,
				eventName: KnownEvents.withdraw
			});
		});
	}

	private getFormattedDate(): string {
		return DateFormatter.formatDate(new Date(this.data.createdDate), 'default', { month: 'long', day: 'numeric', year: 'numeric' });
	}

	private getFormattedTime(): string {
		return DateFormatter.formatTime(new Date(this.data.createdDate), 'default', { hour: '2-digit', minute: '2-digit' });
	}
}
