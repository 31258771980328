import { Component } from '@angular/core';

@Component({
	selector: 'pipelines-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title: string = 'BlueFin.Web: Pipelines Client';
}
